import React from 'react'

function Idea() {
    return (
        <div>
            I Will Note Idea In This Page
        </div>
    )
}

export default Idea